import * as React from "react";
import useTranslation from 'next-translate/useTranslation'
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import ChevronRight from "../icons/ChevronRight";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import OmniLink from './OmniLink';
import { useCurrentBrowserPath } from "@/utils/router";

interface SidebarNavItemProps {
  id: string
  href: string
  label: string
  subLabel?: string
}

interface SidebarNavProps {
  items: SidebarNavItemProps[]
}

const LinkTab: React.FC<SidebarNavItemProps> = ({ href, label, subLabel }) => {
  return (
    <Link
      role="tabpanel"
      href={href}
      component={OmniLink}
      sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", color: "#000", textDecoration: "#000", borderTop: "1px solid #dfdfdf", "&:last-child": {borderBottom: "1px solid #dfdfdf"}  }}
    >
      <Box px="1.5em" py="1.25em" sx={{ maxWidth: "calc(100% - 31px)", width: "100%", whiteSpace: "break-spaces" }}>
        {label &&
          <Typography component="span" sx={{
            fontSize: "16px",
            fontWeight: "600",
            letterSpacing: "0.1px",
            lineHeight: "24px",
          }}>{label}</Typography>
        }
        {subLabel &&
          <Typography component="span" ml="8px" sx={{
            fontSize: "12px",
            letterSpacing: "0.1px",
            lineHeight: "20px",
          }}>{subLabel}</Typography>
        }
      </Box>

      <Box sx={{ paddingRight: '1.5em', py: '1.25em' }}>
        <ChevronRight width="7px" height="11px" />
      </Box>
    </Link>
  );
}

const SidebarNav: React.FC<SidebarNavProps> = ({ items }) => {
  const { t } = useTranslation('common');
  const currentBrowserPath = useCurrentBrowserPath();

  const activeIndex = items.findIndex((item) => {
    return decodeURI(currentBrowserPath) === item.href;
  });

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        marginBottom: '40px',
      }}
    >
      <Tabs
        orientation="vertical"
        value={activeIndex}
        aria-label={t('sidebar-nav-tabs-label',{}, { default: 'Vertical tabs example' })}
        textColor="inherit"
        sx={{
          borderColor: "divider",
          backgroundColor: "#f1f1f1",
          [`& .${tabsClasses.indicator}`]: {
            left: 0,
            width: "4px",
          },
        }}
      >
        {items.map((item, index) => {
          return <LinkTab key={index} {...item} />
        })}
      </Tabs>
    </Box>
  );
}

export default SidebarNav;
