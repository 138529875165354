import React, { useCallback, useEffect, useReducer, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import theme from '@/mui/theme';
import GoogleMapReact from 'google-map-react';
import { WPContacts } from '@/data/wp/operations/queries/fragments/fascia-contacts';
import SidebarNav from '@/components/ui/SidebarNav';
import { sanitize } from '@/utils/miscellaneous';

const MapContainer = styled(Box)({
  width: '100%',
  height: '350px',
  '& iframe + div': {
    border: 'none!important',
  },
}) as typeof Box;

const MainTitle = styled(Typography)({
  fontSize: "51px",
  lineHeight: "54px",
  fontWeight: "800",
  letterSpacing: "0",
  marginBottom: "28px",
}) as typeof Typography;

const MainDesc = styled(Typography)({
  [theme.breakpoints.up('sm')]: {
    columnCount: '2',
    columnGap: '32px',
  },
  'hr': {
    breakAfter: 'column',
    margin: 0,
    border: 0,
  },
  'h2': {
    fontSize: "18px",
    lineHeight: "25px",
    fontWeight: "bold",
    letterSpacing: "0",
    marginBottom: "9px",
    breakAfter: "avoid",
  },
  'h3, h4, h5': {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "bold",
    letterSpacing: "0",
    margin: "0",
    breakAfter: "avoid",
  },
  'p': {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    '+ h2, + h3, + h4, + h5': {
      marginTop: "35px",
    },
    'a': {
      color: '#000',
      textDecoration: 'underline',
    },
  },
}) as typeof Typography;

const RegionTitle = styled(Typography)({
  fontSize: "34px",
  lineHeight: "38px",
  fontWeight: "bold",
  letterSpacing: "-0.23px",
  paddingBottom: "18px",
  borderBottom: "3px solid #ff5c04"
}) as typeof Typography;

const RegionSubtitle = styled(Typography)({
  fontSize: "18px",
  lineHeight: "25px",
  fontWeight: "bold",
  letterSpacing: "0",
  paddingBottom: "19px",
  marginTop: "21px",
  marginBottom: "0",
  borderBottom: "1px solid #cccfd4"
}) as typeof Typography;

const RegionDesc = styled(Typography)({
  paddingTop: '24px',
  [theme.breakpoints.up('sm')]: {
    columnCount: '2',
    columnGap: '32px',
  },
  'hr': {
    breakAfter: 'column',
    margin: 0,
    border: 0,
  },
  'h2': {
    fontSize: "18px",
    lineHeight: "25px",
    fontWeight: "bold",
    letterSpacing: "0",
    margin: "0 0 9px",
    breakAfter: "avoid",
  },
  'h3, h4, h5': {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "bold",
    letterSpacing: "0",
    margin: "0 0 0",
    breakAfter: "avoid",
  },
  'p': {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0",
    display: "block",
    margin: "16px 0",
    breakInside: 'avoid',
    '+ h2, + h3, + h4, + h5': {
      marginTop: "30px",
    },
    'a': {
      color: '#000',
      textDecoration: 'underline',
    },
  },
}) as typeof Typography;

enum MarkersActionKind {
  ADD = 'ADD',
  ADD_BATCH = 'ADD_BATCH',
  REMOVE = 'REMOVE',
  CLEAR = 'CLEAR',
}

interface MarkersAction {
  type: MarkersActionKind
  payload?: any
}

interface MarkerInfo {
  regionIndex: number
  markerIndex: number
  markerZoom: number,
  gMapMarker: any,
  infowindow: any,
}

const MAP_DEFAULT_ZOOM = 6;

const parseMapZoom = (zoom: string) => {
  return zoom ? parseInt(zoom) : MAP_DEFAULT_ZOOM;
}

interface ContactsProps extends WPContacts {
  _data: {
    _pageTitle?: string,
    _pageContent?: string,
    _sideMenus?: object,
  },
};

const Contacts: React.FC<ContactsProps> = ({ title: titleProp, desc: descProp, map: mapProp, regions: regionsProp, _data }) => {
  const [currentRegion, setCurrentRegion] = useState<{ regionIndex: number } | null>(null);
  const [gmap, setGmap] = useState<any>(null);
  const [gmapsApi, setGMapsApi] = useState<any>(null);
  const mapZoom = parseMapZoom(mapProp?.center?.zoom);

  const [_, dispatchMarkers] = useReducer((state: MarkerInfo[], action: MarkersAction): MarkerInfo[] => {
    switch (action.type) {
      case MarkersActionKind.ADD:
        return [
          ...state,
          {
            ...action.payload
          }
        ]

      case MarkersActionKind.ADD_BATCH:
        return [
          ...action.payload
        ]

      case MarkersActionKind.REMOVE:
        return state.filter((_, index) => index != action.payload);

      case MarkersActionKind.CLEAR:
        for (let i = 0; i < state.length; i++) {
          state[i].gMapMarker.setMap(null);
        }
        return [];

      default:
        return state;
    }
  }, []);

  // See https://github.com/google-map-react/google-map-react-examples
  const onGoogleApiLoaded = useCallback(({ map: gmap, maps: gmapsApi }) => {
    setGmap(gmap);
    setGMapsApi(gmapsApi);
  }, []);

  // se è presente una sola regione, la mostro subito
  useEffect(() => {
    if (regionsProp.length === 1) {
      setCurrentRegion({ regionIndex: 0 });
    } else {
      setCurrentRegion(null);
    }
  }, [regionsProp]);

  // a seguito dell'inizializzazione della google map o se se l'utente cambia pagina
  // rimuovo gli eventuali marker precedenti e inserisco quelli delle nuove regions
  useEffect(() => {
    if (gmap && gmapsApi) {
      const bounds = new gmapsApi.LatLngBounds();
      const markersTemp: MarkerInfo[] = [];

      dispatchMarkers({ type: MarkersActionKind.CLEAR });

      regionsProp.map((region, regionIndex) => {
        region?.markers?.map((markerData, markerIndex) => {
          if (!markerData?.coordinates || !markerData?.coordinates?.latitude || !markerData?.coordinates.longitude) {
            return;
          }

          const position = {
            lat: markerData.coordinates.latitude,
            lng: markerData.coordinates.longitude,
          };

          bounds.extend(new gmapsApi.LatLng(position.lat, position.lng));

          const marker: MarkerInfo = {
            regionIndex,
            markerIndex,
            markerZoom: parseMapZoom(markerData?.coordinates?.zoom || '6'),
            gMapMarker: new gmapsApi.Marker({
              position,
              map: gmap,
            }),
            infowindow: new gmapsApi.InfoWindow({
              disableAutoPan: true,
              content: `<div style="width: 200px;">
                ${markerData.title}
              </div>`
            })
          };

          marker.gMapMarker.addListener('mouseover', () => {
            marker.infowindow.open(gmap, marker.gMapMarker);
          });

          marker.gMapMarker.addListener('mouseout', () => {
            marker.infowindow.close();
          });

          marker.gMapMarker.addListener('click', () => {
            setCurrentRegion({
              regionIndex: marker.regionIndex
            })
          });

          markersTemp.push(marker);
        });
      });

      // se ho dei marker centratura e zoom vengono calcolati automaticamente per far vedere tutti i marker
      // altrimenti rimane buono centro e zoom impostati inizialmente (dalle info generiche della fascia Contatti in Wordpress)
      if (markersTemp.length > 0) {
        gmap.fitBounds(bounds);
      }

      // se ho un solo marker invece uso il livello di zoom impostato su quel marker (dalla fascia Contatti in Wordpress)
      if (markersTemp.length === 1) {
        gmap.setZoom(markersTemp[0].markerZoom);
      }

      dispatchMarkers({ type: MarkersActionKind.ADD_BATCH, payload: markersTemp});
    }
  }, [gmap, gmapsApi, regionsProp]);

  // preparo i menu laterali
  const sidebarNavMenus = _data?._sideMenus && Object.entries(_data?._sideMenus).map(([, menu]) => {
    return menu?.edges?.map((item: any) => {
      return {
        id: item?.node?.id,
        href: item?.node?.path,
        label: item?.node?.label
      }
    });
  });

  return <Box sx={{ position: 'relative', pt: '79px', pb: { sm: '68px', xs: '72px' } }}>
    <Container>
      <Grid container rowSpacing={0}>
        <Grid item xs={12} md={4} lg={3}>
          {sidebarNavMenus?.map((menu, index) => {
            return <SidebarNav key={index} items={menu} />
          })}
        </Grid>
        <Grid item xs={12} md={7} lg={9}>
          <Box sx={{ border: { xs: 0, sm: "1px solid rgba(151, 151, 151, 50%)" } }}>

            <Grid container rowSpacing={0} my={6}>
              <Grid item xs={0} sm={1} />
              <Grid item xs={12} sm={10}>
                <Box>
                  {titleProp &&
                    <MainTitle>{titleProp}</MainTitle>
                  }
                  {descProp &&
                    <MainDesc component="div" dangerouslySetInnerHTML={{__html: sanitize(descProp)}} />
                  }
                </Box>
              </Grid>
              <Grid item xs={0} sm={1} />
            </Grid>

            {mapProp.showMap &&
              <Box sx={{ background: '#ddd' }}>
                {mapProp.title &&
                  <Box sx={{ background: '#4f4f4f' }}>
                    <Grid container rowSpacing={0}>
                      <Grid item xs={0} sm={1} />
                      <Grid item xs={12} sm={10}>
                        <Typography component="div" sx={{ display: 'flex', alignItems: 'center', minHeight: '80px', padding: '15px', color: '#fff', fontSize: '34px', lineHeight: '34px', letterSpacing: '-0.23px', fontWeight: 'bold' }}>
                          {mapProp.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                }
                <MapContainer>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '' }}
                    yesIWantToUseGoogleMapApiInternals={true}
                    center={{
                      lat: mapProp.center.latitude,
                      lng: mapProp.center.longitude
                    }}
                    zoom={mapZoom}
                    onGoogleApiLoaded={onGoogleApiLoaded}
                  />
                </MapContainer>
              </Box>
            }

            {regionsProp.length > 0 && (!mapProp.showMap || (currentRegion?.regionIndex !== null && currentRegion?.regionIndex !== undefined)) &&
              <Grid container rowSpacing={0} mt={6} mb={3}>
                <Grid item xs={0} sm={1} />
                <Grid item xs={12} sm={10}>
                  {regionsProp.map((region: any, index: number) => {
                    if (!mapProp.showMap || index === currentRegion?.regionIndex) {
                      return <Box key={index} mb={4}>
                        {region.title &&
                          <RegionTitle>{region.title}</RegionTitle>
                        }
                        {region.subtitle &&
                          <RegionSubtitle>{region.subtitle}</RegionSubtitle>
                        }
                        {region.desc &&
                          <RegionDesc component="div" dangerouslySetInnerHTML={{__html: sanitize(region.desc)}} />
                        }
                      </Box>
                    }
                  })}
                </Grid>
                <Grid item xs={0} sm={1} />
              </Grid>
            }
          </Box>
        </Grid>
      </Grid>
    </Container>
  </Box>
}

export default Contacts;
